import { Button, Col, Container, Div, Row, Text, Anchor } from "atomize"
import React, { useState } from "react"
import Demo0 from "../images/Screen Shot 2022-05-31 at 15.10.42.png"
import Demo1 from "../images/Screen Shot 2021-12-03 at 20.13.15.png"
import Demo2 from "../images/lounge-business-analytics.png"
import { Fade } from "react-awesome-reveal"
import firebase from "gatsby-plugin-firebase"

const selectingStyleButton = {
  textColor: "white",
  hoverTextColor: "info700",
  bg: "info700",
  hoverBg: "white",
  border: "1px solid",
  borderColor: "info700",
  hoverBorderColor: "info700",
}

const nonSelectingStyleButton = {
  textColor: "info700",
  hoverTextColor: "white",
  bg: "white",
  hoverBg: "info700",
  border: "1px solid",
  borderColor: "info700",
  hoverBorderColor: "info700",
}

const OverviewFeature = () => {
  const [selectingFeature, setSelectingFeature] = useState("call")

  let img = ""
  if (selectingFeature === "call") {
    img = Demo0
  }
  if (selectingFeature === "data") {
    img = Demo1
  }
  if (selectingFeature === "report") {
    img = Demo2
  }
  return (
    <Div
      tag="section"
      p={{
        l: { md: "4rem" },
        r: { md: "4rem" },
      }}
      m={{
        b: { md: "6rem" },
      }}
      flexDir="row"
    >
      <Row align={"center"}>
        <Col size={{ sm: 12, lg: 4 }}>
          <div onMouseOver={() => setSelectingFeature("call")}>
            <Anchor href={`../features#call`}>
              <Button
                h="2.5rem"
                p={{ x: "1rem" }}
                textSize="body"
                {...(selectingFeature === "call"
                  ? selectingStyleButton
                  : nonSelectingStyleButton)}
                m={{ r: "0.5rem" }}
              >
                Gọi tự động hàng nghìn cuộc gọi
              </Button>
            </Anchor>
          </div>

          <div onMouseOver={() => setSelectingFeature("data")}>
            <Anchor href={`../features#data`}>
              <Button
                h="2.5rem"
                p={{ x: "1rem" }}
                textSize="body"
                {...(selectingFeature === "data"
                  ? selectingStyleButton
                  : nonSelectingStyleButton)}
                m={{ r: "0.5rem", t: "1rem" }}
              >
                Làm giàu thông tin khách hàng
              </Button>
            </Anchor>
          </div>
          <div onMouseOver={() => setSelectingFeature("report")}>
            <Anchor href={`../features#report`}>
              <Button
                h="2.5rem"
                p={{ x: "1rem" }}
                textSize="body"
                onHover={() => setSelectingFeature("report")}
                {...(selectingFeature === "report"
                  ? selectingStyleButton
                  : nonSelectingStyleButton)}
                m={{ r: "0.5rem", t: "1rem" }}
              >
                Báo cáo, thống kê
              </Button>
            </Anchor>
          </div>
        </Col>
        <Col
          size={{ sm: 12, lg: 8 }}
          justify="center"
          align="center"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <img src={img} style={{ maxHeight: "600px", width: "100%" }} />
        </Col>
      </Row>
    </Div>
  )
}

export default OverviewFeature
