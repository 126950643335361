import React, { useEffect, useRef } from "react"
import { Button, Col, Container, Row, Text } from "atomize"
import DemoBot from "../images/Screen Shot 2021-12-01 at 20.02.01.png"
import DemoBot0 from "../images/Screen Shot 2021-12-01 at 20.05.48.png"
import DemoBot1 from "../images/Screen Shot 2021-12-01 at 20.05.55.png"
import DemoBot2 from "../images/Screen Shot 2021-12-01 at 20.06.00.png"
import DemoBot3 from "../images/Screen Shot 2021-12-02 at 19.30.10.png"
import DemoBot4 from "../images/Screen Shot 2021-12-02 at 19.30.40.png"
import DemoBot5 from "../images/Screen Shot 2021-12-02 at 19.30.52.png"
import Demo6 from "../images/Screen Shot 2022-05-31 at 15.08.48.png"
import Illustration1 from "../images/clip-online-shopping-7.png"
import { Parallax } from "react-scroll-parallax"
import { Zoom, Fade } from "react-awesome-reveal"

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const FeaturesDetail = () => {
  const callRef = useRef(null)
  const dataRef = useRef(null)
  const reportRef = useRef(null)

  // useEffect(() => {
  //   switch (window.location.hash.slice(1)) {
  //     case 'call':
  //       scrollToRef(callRef);
  //       break
  //     case 'data':
  //       scrollToRef(dataRef);
  //       break
  //     case 'report':
  //       scrollToRef(reportRef);
  //       break
  //   }
  // }, [window.location.hash])

  return (
    <div>
      <section ref={callRef} id="#call">
        <Row align={"center"}>
          <Col size={12}>
            <Container d="flex" flexDir="column" align="left">
              <Text
                tag="h1"
                textWeight="700"
                textAlign="center"
                textSize="display3"
                fontFamily="secondary"
                textColor={"black"}
                m={{ t: "3rem", b: "3rem" }}
                style={{ fontSize: "28px" }}
              >
                Gọi tự động hàng nghìn cuộc gọi
              </Text>
            </Container>
          </Col>
        </Row>
        <Row align={"center"} style={{ flexWrap: "wrap" }}>
          <Col size={{ sm: 12, md: 12, lg: 6 }}>
            <Parallax y={[-30, 0]} tagOuter="figure">
              <Fade direction="up">
                <img src={DemoBot} width="100%" />
                <div
                  style={{
                    padding: "0 24px",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  <b>Thiết kế kịch bản đơn giản, linh hoạt</b>
                  <br />
                  <span style={{ fontSize: "16px" }}>
                    Các block nội dung cá nhân hoá được nối ghép nối dễ dàng
                  </span>
                </div>
              </Fade>
            </Parallax>
          </Col>
          <Col size={{ sm: 12, md: 12, lg: 6 }}>
            <Row>
              <Col size={{ sm: 12, md: 12, lg: 6 }}>
                <Parallax y={[10, -10]} tagOuter="figure">
                  <Fade delay={400} direction="up">
                    <img
                      src={DemoBot0}
                      width="100%"
                      style={{
                        borderRadius: "12px",
                        border: "1px solid rgba(0,0,0,0.1)",
                      }}
                    />
                    <p className="caption">
                      Gắn nhãn cho từng trường hợp khách hàng trả lời
                    </p>
                  </Fade>
                </Parallax>
              </Col>
              <Col size={{ sm: 12, md: 12, lg: 6 }}>
                <Parallax y={[20, -20]} tagOuter="figure">
                  <Fade delay={600} direction="up">
                    <img
                      src={DemoBot1}
                      width="100%"
                      style={{
                        borderRadius: "12px",
                        border: "1px solid rgba(0,0,0,0.1)",
                      }}
                    />
                    <p className="caption">
                      Có thể ghi âm nội dung thay thế cho giọng AI với những nội
                      dung kịch bản cố định
                    </p>
                  </Fade>
                  <Fade delay={800} direction="up">
                    <img
                      src={DemoBot2}
                      width="100%"
                      style={{
                        borderRadius: "12px",
                        marginTop: "20px",
                        border: "1px solid rgba(0,0,0,0.1)",
                      }}
                    />
                    <p className="caption">
                      Gửi thông báo tức thời cho nhân viên trong các trường hợp
                      cần thiết
                    </p>
                  </Fade>
                </Parallax>
              </Col>
            </Row>
          </Col>
        </Row>

        <div style={{ height: "128px" }} />
        <Row>
          <Col size={{ sm: 12, md: 12, lg: 6 }}>
            <Fade delay={1200}>
              <Parallax y={[-40, 0]} tagOuter="figure">
                <img
                  src={DemoBot3}
                  width="100%"
                  style={{
                    borderRadius: "12px",
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}
                />
                <div
                  style={{
                    padding: "0 24px",
                    fontSize: "20px",
                    textAlign: "center",
                    marginBottom: "32px",
                  }}
                >
                  Lựa chọn giọng nói phù hợp với chiến dịch
                </div>
                <img
                  src={DemoBot5}
                  width="100%"
                  style={{
                    borderRadius: "12px",
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}
                />
                <div
                  style={{
                    padding: "0 24px",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  Cấu hình các trường hợp gọi lại và thời gian gọi lại khi khách
                  hàng không nghe máy
                </div>
              </Parallax>
            </Fade>
          </Col>
          <Col size={{ sm: 12, md: 12, lg: 6 }}>
            <Zoom>
              <Text
                tag="h1"
                textWeight="700"
                textAlign="center"
                textSize="display3"
                fontFamily="secondary"
                textColor={"black"}
                m={{ t: "3rem", b: "8rem" }}
                style={{ fontSize: "28px" }}
              >
                Cài đặt chiến dịch thuận tiện
              </Text>
            </Zoom>
            <Fade triggerOnce delay={700}>
              <Parallax y={[10, -10]} tagOuter="figure">
                <img
                  src={DemoBot4}
                  width="100%"
                  style={{
                    borderRadius: "12px",
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}
                />
                <div
                  style={{
                    padding: "0 24px",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  Cài đặt các khung giờ chạy chiến dịch phù hợp
                </div>
              </Parallax>
            </Fade>
          </Col>
        </Row>
      </section>
      <div style={{ height: "128px" }} />
      <section id="#data" ref={dataRef}>
        <div style={{ textAlign: "center" }}>
          <img src={Illustration1} width="50%" />
          <Fade triggerOnce>
            <Text
              tag="h1"
              textWeight="700"
              textAlign="center"
              textSize="display3"
              fontFamily="secondary"
              textColor={"black"}
              m={{ t: "0.5rem", b: "3rem" }}
              style={{ fontSize: "28px" }}
            >
              Làm giàu thông tin khách hàng
            </Text>
          </Fade>
          <Fade triggerOnce>
            <Text
              tag="h1"
              textWeight="400"
              textAlign="center"
              textSize="display3"
              fontFamily="secondary"
              textColor={"black"}
              style={{ fontSize: "20px" }}
            >
              Tìm kiếm số điện thoại khách hàng từ mạng xã hội facebook (hội
              nhóm, tương tác, fanpage)
            </Text>
          </Fade>
          <Fade triggerOnce>
            <Text
              tag="h1"
              textWeight="400"
              textAlign="center"
              textSize="display3"
              fontFamily="secondary"
              textColor={"black"}
              style={{ fontSize: "20px" }}
            >
              Dự đoán giới tính thông qua dữ liệu mạng xã hội và tên khách hàng
            </Text>
          </Fade>
          <Fade triggerOnce>
            <Text
              tag="h1"
              textWeight="400"
              textAlign="center"
              textSize="display3"
              fontFamily="secondary"
              textColor={"black"}
              style={{ fontSize: "20px" }}
            >
              Dán nhãn phân loại khách hàng qua các trường hợp trả lời callbot
              khác nhau
            </Text>
          </Fade>
        </div>
      </section>
      <div style={{ height: "128px" }} />
      <section id="#report" ref={reportRef}>
        <Container>
          <Row>
            <Col size={{ md: 12, lg: 6 }} style={{ textAlign: "center" }}>
              <img src={Demo6} width={500} />
            </Col>
            <Col style={{ textAlign: "left" }}>
              <Fade triggerOnce>
                <Text
                  tag="h1"
                  textWeight="700"
                  textSize="display3"
                  fontFamily="secondary"
                  textColor={"black"}
                  m={{ t: "0.5rem", b: "3rem" }}
                  style={{ fontSize: "28px" }}
                >
                  Báo cáo tiến độ cuộc gọi
                </Text>
              </Fade>
              <Fade triggerOnce>
                <Text
                  tag="h1"
                  textWeight="400"
                  textSize="display3"
                  fontFamily="secondary"
                  textColor={"black"}
                  style={{ fontSize: "20px" }}
                >
                  👉 Báo cáo tổng chi phí và tổng thời gian gọi
                </Text>
              </Fade>
              <Fade triggerOnce>
                <Text
                  tag="h1"
                  textWeight="400"
                  textSize="display3"
                  fontFamily="secondary"
                  textColor={"black"}
                  style={{ fontSize: "20px" }}
                >
                  👉 Thống kê số cuộc gọi thành công, số cuộc gọi khách hàng trả
                  lời
                </Text>
              </Fade>
              <Fade triggerOnce>
                <Text
                  tag="h1"
                  textWeight="400"
                  textSize="display3"
                  fontFamily="secondary"
                  textColor={"black"}
                  style={{ fontSize: "20px" }}
                >
                  👉 Thống kê thời lượng cuộc gọi
                </Text>
              </Fade>
              <Fade triggerOnce>
                <Text
                  tag="h1"
                  textWeight="400"
                  textSize="display3"
                  fontFamily="secondary"
                  textColor={"black"}
                  style={{ fontSize: "20px" }}
                >
                  👉 Thống kê số cuộc gọi theo thời gian
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default FeaturesDetail
