import React, { useState } from "react"
import MessengerChat from "../components/messenger"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/common/header"
import { Container, Div, Row, Col, Text, Button } from "atomize"
import OverviewFeature from "../components/overviewFeature"
import FeaturesDetail from "../components/featuresDetail"
import { ParallaxProvider } from "react-scroll-parallax"

const Features = () => {
  const layout = "layout-2"
  return (
    <Layout>
      <MessengerChat />

      <React.Fragment>
        <SEO
          title="Tính năng callbot gọi tự động uCall"
          description="uCall - Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
        />
        <meta
          property="og:title"
          content="Tính năng callbot gọi tự động uCall"
        />
      </React.Fragment>
      <React.Fragment>
        <Header layout={layout} />
      </React.Fragment>

      <ParallaxProvider>
        <OverviewFeature />
        <FeaturesDetail />
        <div style={{ height: "128px" }} />
      </ParallaxProvider>
      <Container>
        <Text></Text>
      </Container>
    </Layout>
  )
}

export default Features
